/**
 *
 * CheckboxIcon
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

export const Camera = ({
  fill,
  width = '100%',
  height = '100%',
  style = {},
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height={height}
    width={width}
    style={style}
    fill={'#8dc63f'}
  >
    <g>
      <path
        fill={'#8dc63f'}
        d="M35,27.01501c-7.75,0-14.06,6.29999-14.06,14.06c0,7.75,6.31,14.06,14.06,14.06c7.76001,0,14.07001-6.31,14.07001-14.06   C49.07001,33.315,42.76001,27.01501,35,27.01501z M34.31421,33.75378c-0.53906,0.05078-1.08203,0.16016-1.6123,0.32666   c-2.61035,0.81543-4.56152,3.08643-4.97363,5.78564c-0.13379,0.87939-0.12207,1.74854,0.03516,2.58252   c0.10254,0.54248-0.25488,1.06543-0.79688,1.16797c-0.0625,0.01172-0.125,0.01709-0.18652,0.01709   c-0.47168,0-0.8916-0.33447-0.98145-0.81494c-0.19922-1.05566-0.21484-2.15039-0.04688-3.25391   c0.52539-3.44922,3.01953-6.35107,6.35254-7.39355c0.66504-0.20752,1.3457-0.34521,2.02441-0.40869   c0.54102-0.05176,1.03711,0.35254,1.08884,0.90234C35.26831,33.21472,34.86499,33.70251,34.31421,33.75378z"
      ></path>
      <g>
        <path
          fill={'#8dc63f'}
          d="M35,20.88501c-11.16998,0-20.17999,9.08997-20.17999,20.19C14.82001,52.22498,23.87006,61.255,35,61.255    c11.20001,0,20.19-9.09003,20.19-20.17999C55.19,29.90503,46.11005,20.88501,35,20.88501z M35,57.13501    c-8.85999,0-16.06-7.20001-16.06-16.06s7.20001-16.06,16.06-16.06c8.86005,0,16.07001,7.20001,16.07001,16.06    S43.86005,57.13501,35,57.13501z"
        ></path>
      </g>
      <path
        fill={'#8dc63f'}
        d="M12.82001,41.07501c0-6.08777,2.46283-11.60773,6.44189-15.62006H3v22.31982h10.8703   C13.19775,45.65851,12.82001,43.41211,12.82001,41.07501z"
      ></path>
      <path
        fill={'#8dc63f'}
        d="M57.19,41.07501c0,2.3371-0.37775,4.5835-1.05029,6.69977H67V25.45496H50.74695   C54.72723,29.46729,57.19,34.98724,57.19,41.07501z"
      ></path>
      <path
        fill={'#8dc63f'}
        d="M62.25,13.495H46.30005v-2.08997c0-1.47003-1.19-2.66003-2.66003-2.66003H26.37006   c-1.46002,0-2.65002,1.19-2.65002,2.66003V13.495h-5.23999v-2.5c0-1-0.82001-1.82001-1.82001-1.82001h-2.33002   c-1,0-1.82001,0.82001-1.82001,1.82001v2.5H7.75c-2.62,0-4.75,2.13-4.75,4.75v5.20996h18.53802   C25.27405,20.59149,29.94049,18.88501,35,18.88501c5.06366,0,9.73248,1.70648,13.46997,4.56995H67V18.245   C67,15.625,64.87006,13.495,62.25,13.495z M13.34906,20.79987c-1.284,0-2.32489-1.04089-2.32489-2.32489   s1.04089-2.32495,2.32489-2.32495s2.32489,1.04095,2.32489,2.32495S14.63306,20.79987,13.34906,20.79987z M61.66443,19.64209   c0,0.55231-0.44775,1-1,1h-8.02692c-0.55231,0-1-0.44769-1-1V17.3078c0-0.55225,0.44769-1,1-1h8.02692c0.55225,0,1,0.44775,1,1   V19.64209z"
      ></path>
      <path
        fill={'#8dc63f'}
        d="M3,49.77478v1.63025c0,2.62,2.13,4.75,4.75,4.75h11c-1.72601-1.85736-3.1217-4.01794-4.13397-6.38025H3z"
      ></path>
      <path
        fill={'#8dc63f'}
        d="M51.26001,56.15503H62.25c2.62006,0,4.75-2.13,4.75-4.75v-1.63025H55.39398   C54.38171,52.13708,52.98602,54.29767,51.26001,56.15503z"
      ></path>
    </g>
  </svg>
);

Camera.propTypes = {
  checked: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  fill: PropTypes.string,
};

export default Camera;
