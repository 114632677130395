/**
 *
 * Share
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

export const Share = ({
  fill="#8dc63f",
  width = '100%',
  height = '100%',
  style = {},
}) => (
  <svg height={height} width={width} style={style} viewBox="0 0 100 100">
    <path
      fill={fill}
      d="M77.4,57.9c-7.1,0-12.9,5.8-12.9,13s5.8,13,12.9,13c7.1,0,13-5.8,13-13c0-4.6-2.4-8.7-6.1-11  c0.2-1.6,0.3-3.2,0.3-4.9c0-11.8-5.9-22.7-15.8-29.1l-3.2,5c8.2,5.3,13.1,14.3,13.1,24.1c0,1-0.1,2-0.2,2.9  C78.2,58,77.8,57.9,77.4,57.9z M77.4,77.9c-3.9,0-7-3.1-7-7c0-3.9,3.1-7,7-7s7,3.1,7,7C84.5,74.8,81.3,77.9,77.4,77.9z M49.5,10.1  c-7.1,0-12.8,5.7-12.9,12.8c-1.5,0.6-3,1.4-4.4,2.3C22,31.2,15.7,41.9,15.3,53.7l5.9,0.2c0.3-9.8,5.5-18.6,13.9-23.7  c0.9-0.5,1.8-1,2.7-1.4C40,33.1,44.4,36,49.5,36c7.1,0,13-5.8,13-13S56.6,10.1,49.5,10.1z M49.5,30.1c-3.9,0-7-3.1-7-7  c0-3.9,3.1-7,7-7s7,3.1,7,7C56.5,26.9,53.4,30.1,49.5,30.1z M49.9,84c-6.1,0-12-1.9-16.9-5.5c1.6-2.1,2.5-4.8,2.5-7.6  c0-7.1-5.8-13-13-13c-7.1,0-12.9,5.8-12.9,13s5.8,13,12.9,13c2.1,0,4.1-0.5,5.9-1.4c6.1,4.8,13.6,7.5,21.5,7.5  c5.3,0,10.3-1.2,15.1-3.4l-2.6-5.3C58.5,83,54.3,84,49.9,84z M15.5,70.9c0-3.9,3.1-7,7-7s7,3.1,7,7c0,3.9-3.1,7-7,7  S15.5,74.8,15.5,70.9z"
    />
  </svg>
);

Share.propTypes = SVGPropTypes;

export default Share;
