/**
 *
 * Home
 *
 *
 */

import React, { memo } from 'react';
// import { connect } from 'react-redux';
import { compose } from 'redux';
import { Router } from '@reach/router';
import styled from 'styled-components';

import Landing from 'containers/Landing';
import Login from 'containers/Auth/Login';
import Registration from 'containers/Registration';

import { LOGIN_ROUTE, SIGNUP_ROUTE } from 'utils/routes';

const Home = () => {
  return (
    <Container>
      <Router>
        <Landing path="/" />
        {/* <Login path={`${LOGIN_ROUTE}/`} /> */}
        <Login path={LOGIN_ROUTE} />
        {/* <Registration path={`${SIGNUP_ROUTE}/`} closeModal={() => {}} /> */}
        <Registration path={SIGNUP_ROUTE} closeModal={() => {}} />
      </Router>
    </Container>
  );
};

Home.propTypes = {};

// const mapStateToProps = ({ user }) => ({ user });
// const withState = connect(mapStateToProps);

export default compose(
  memo,
  // withState,
)(Home);

const Container = styled.div``;
