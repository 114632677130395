import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Theme from 'utils/theme';

const { colors } = Theme;

const BlockQuote = ({ text }) => (
  <Container>
    <Quote>{text}</Quote>
  </Container>
);

BlockQuote.propTypes = {
  text: PropTypes.string,
};

export default BlockQuote;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Quote = styled.blockquote`
  font-family: 'Libre Baskerville', serif;
  font-size: 3em;
  line-height: 150%;
  font-style: italic;
  padding: 1em 2em;
  /* &:before {
    content:"〝";
    margin-left:-1em;
    padding-right: 0.3em;
  }
  &:after {
    content:"〞";
    margin-right: -1em;
    padding-left: 0.3em;
  }
  &:before,
  &:after {
    display: inline-block;
  } */
`;
