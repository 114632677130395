/**
 *
 * Landing Page
 *
 *
 */

import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import { connect } from 'react-redux';
import { compose } from 'redux';
import useScrollPosition from '@react-hook/window-scroll';

import PropTypes from 'prop-types';

import Card from 'components/Card';
import Vimeo from 'components/lib/Vimeo';
import Button from 'components/Elements/Button';
import BlockQuote from 'components/Elements/BlockQuote';
import PriceBlock from 'components/PriceBlock';
import Memorial from 'components/icons/Memorial';
import Chevron from 'components/icons/Chevron';
import Share from 'components/icons/Share';
import WhiteGreenPin from 'components/icons/Ripspot/whiteGreenPin';
import Location from 'components/icons/Location';
import Camera from 'components/icons/Camera';
import locateImage from 'images/content/locate_blank.jpg';
import createImage from 'images/content/create_blank.jpg';
import shareImage from 'images/content/share_blank.jpg';
import TileOneSpacer from 'images/content/pin_ack.jpg';
import TileTwoSpacer from 'images/content/pin_lighthouse.jpg';
import TileThreeSpacer from 'images/content/pin_mountains.jpg';
import FAQPage from 'containers/FAQPage';
import {
  MAP_SEARCH_ROUTE,
  FAQ_ROUTE,
  MAP_CR_LOCATIONS_ROUTE,
} from 'utils/routes';

import LazyLoad from 'react-lazyload';

import { openSignupModal } from 'actions/modals';

const Landing = ({ props, dispatch, user }) => {
  const intro = useRef();
  const container = useRef();
  const [navFixed, setNavFixed] = useState(false);

  const [appReady, setAppReady] = useState(false);

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  const scrollY = useScrollPosition();

  useEffect(() => {
    if (navFixed) {
      setNavFixed(false);
      document.body.classList.remove('fix-nav');
    }
    const width = window.innerWidth;
    const height = window.innerHeight;
    setWindowWidth(width);
    setWindowHeight(height - 55);
    setAppReady(true);
  }, []);

  useEffect(() => {
    if (scrollY >= 50) {
      document.body.classList.add('fix-nav');
      setNavFixed(true);
    } else {
      setNavFixed(false);
      document.body.classList.remove('fix-nav');
    }
  }, [scrollY]);

  const handleCreateRipspot = () => {
    if (user) {
      navigate(MAP_CR_LOCATIONS_ROUTE, { state: { createNewRipspot: true } });
    } else {
      dispatch(openSignupModal());
    }
  };

  return (
    <Container ref={container}>
      <Header
        style={{
          backgroundImage: 'url(images/home-bg-image.jpg)',
          height: windowHeight,
        }}
      >
        {/* {windowWidth >= 1024 && appReady && <Vimeo background />} */}
        <div style={{ zIndex: 2, width: '100%', marginTop: '-20px' }}>
          <SVGWrapper style={{ zIndex: 2 }}>
            <WhiteGreenPin />
          </SVGWrapper>
          <HeaderTitle>A place to celebrate the lives of our pets.</HeaderTitle>
        </div>
        <div className="scroll-arrow">
          <Chevron
            width="3em"
            height="3em"
            fill="#fff"
            onClick={() => {
              intro.current &&
                intro.current.scrollIntoView({ behavior: 'smooth' });
            }}
          />
        </div>
      </Header>

      <Section ref={intro} style={{ marginTop: '30px' }}>
        <TextBlock>
          Welcome to Ripspot®, a more personal, eco-friendly way to celebrate a beloved pet who has passed away. A Ripspot is a virtual pin that marks a meaningful place. Perhaps it was a favorite park, a trail, a beach, or wherever they liked to play.
          <br />
          <br />A Ripspot is ideal for marking the location ashes have been scattered and can serve as a wonderful obituary to be shared with friends and family. 
        </TextBlock>
      </Section>

      <Section style={{ maxWidth: 'none' }} className="full-bleed">
        <Title style={{ maxWidth: '680px', margin: '0px auto 40px' }}>
          How It Works
        </Title>
        <LazyLoad once height={400}>
          <CardsWrapper>
            <Card
              title="Locate"
              // textItems={[
              //   'Choose a meaningful spot',
              // ]}
              SvgComp={Location}
              bgImage={locateImage}
              className="has-bg"
            />
            <Card
              title="Create"
              // textItems={['Write their story and build a photo gallery.']}
              SvgComp={Memorial}
              bgImage={createImage}
              className="has-bg"
            />
            <Card
              title="Share"
              // textItems={['Let others pay tribute to their wonderful life.']}
              SvgComp={Share}
              bgImage={shareImage}
              className="has-bg"
            />
          </CardsWrapper>
        </LazyLoad>
      </Section>

      <Section className="pricing-block">
        <div>
          <Title style={{ maxWidth: '720px', margin: '0px auto 40px' }}>
            Getting Started
          </Title>
          <TextBlock>
            In just a few simple steps, you’ll be able to pin locations, create a photo gallery and biography and share it with friends and family. You can continually add to the Ripspot and even allow others to contribute, from posting memories to sharing their own photos.
            <br/>
            <br />
            We believe the process of celebrating our loved ones should better reflect the lives they lived.
          </TextBlock>
          <CardsWrapper>
            <Card
              textItems={['Upload photos']}
              SvgComp={Camera}
              className="two-up"
            />
            <Card
              textItems={['Find Locations']}
              SvgComp={Location}
              className="two-up"
            />
          </CardsWrapper>
          {/* <Title style={{ marginTop: '50px' }}>Simple and affordable</Title> */}
          {/* <PriceBlock /> */}
          <Button light onClick={handleCreateRipspot}>
            Create Ripspot
          </Button>
        </div>
      </Section>
      <Section className="video-block">
        {appReady && (
          <Vimeo vimeoUrl="https://player.vimeo.com/video/520043851?title=0&byline=0&portrait=0" />
        )}
      </Section>

      <Section className="two-up">
        <LazyLoad height={400} once>
          <img
            src="/images/1-percent-logo-full.png"
            alt="Map filled with pins representing each Ripspot."
          />
        </LazyLoad>
        <div>
          <Title>Our Mission</Title>
          <TextBlock>
            Losing a pet or a loved one will never be easy. At Ripspot we are
            committed to using technology to make the process more relevant,
            affordable and eco-conscious. We are a proud member of 1% for the
            Planet. By giving 1% of our gross revenues to nonprofit
            environmental causes, we are helping ensure our planet and future
            generations thrive.
          </TextBlock>
        </div>
      </Section>

      {/* IMG SPACER SECTION */}
      <Section className="full-bleed" style={{ maxWidth: 'none' }}>
        <LazyLoad height={400} once>
          <CardsWrapper>
            <Card bgImage={TileOneSpacer} className="img-only" />
            <Card bgImage={TileTwoSpacer} className="img-only" />
            <Card bgImage={TileThreeSpacer} className="img-only" />
          </CardsWrapper>
        </LazyLoad>
      </Section>
      <Section className="explore-container">
        <Title>A Place to Celebrate</Title>
        <TextBlock style={{ maxWidth: '820px', margin: '0px auto 40px' }}>
          Mark meaningful places. Write a biography. Create a photo gallery.
          Share with friends and family.
        </TextBlock>
        <LazyLoad height={400} once>
          <img
            src="/images/map-placeholder.jpg"
            alt="Map filled with pins representing each Ripspot."
          />
        </LazyLoad>
        <ExploreMoreBtn>
          <Button primary onClick={() => navigate(MAP_SEARCH_ROUTE)}>
            View Ripspots
          </Button>
        </ExploreMoreBtn>
      </Section>
      <Section className="has-cta">
        <FAQPage limit={3} />
        <Button light onClick={() => navigate(FAQ_ROUTE)}>
          View More FAQs
        </Button>
      </Section>
    </Container>
  );
};

Landing.propTypes = {
  dispatch: PropTypes.func.isRequired,
};
const mapStateToProps = ({ user }) => ({ user });
const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Landing);

const Container = styled.div`
  position: relative;
  color: #000;

  .my-span {
    font-family: 'Libre Baskerville', serif;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: calc(100vh - 55px);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
  }
  .scroll-arrow {
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    cursor: pointer;
    &:before {
      width: 100px;
      content: 'Scroll';
      color: white;
      position: absolute;
      top: -30%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Montserrat', sans-serif;
      font-size: 1.4em;
    }
  }
`;

const Section = styled.section`
  padding: 15px;
  margin: 0 auto;
  margin-bottom: 45px;
  max-width: 680px;

  &.explore-container {
    width: 100%;
    padding: 0px;
    max-width: none;
    margin-bottom: 0px;
    position: relative;
    margin-top: 8em;
    img {
      width: 100%;
      height: 55vh;
      display: block;
      object-fit: cover;
      object-position: center center;
    }
    > p {
      @media all and (max-width: 800px) {
        padding: 15px;
      }
    }
  }

  &.full-bleed {
    padding: 0px;
    width: 100%;
  }

  &.has-cta {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    button {
    }
  }

  &.video-block {
    max-width: 1024px;
    position: relative;
    padding: 0px;
    &:after {
      content: '';
      display: block;
      padding-bottom: 56.25%;
    }
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  &.pricing-block {
    max-width: none;
    background-image: url('images/pin-texture.png');
    background-size: cover;
    background-position: center center;
    > div {
      max-width: 680px;
      margin: 0 auto;
      display: block;
    }
    button {
      display: block;
      margin: 0 auto;
      margin-top: 3em;
    }
    div.two-up {
      padding: 1em 3em;
      border: 2px solid ${({ theme }) => theme.colors.Primary};
      border-radius: 3px;
      ul {
        padding: 0;
      }
      p {
        color: black;
        font-size: 1.8em;
      }
    }
  }

  &.two-up {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
      height: auto;
      max-width: 150px;
      align-self: center;
    }
    > div {
      &.lazyload-wrapper {
        order: 2;
        display: flex;
        justify-content: center;
      }
    }
    @media all and (min-width: 800px) {
      max-width: 780px;
      flex-direction: row;
      justify-content: space-around;
      h3 {
        text-align: left;
        font-size: 2.2rem;
        margin-bottom: 0;
      }
      > div {
        width: 65%;
        &.lazyload-wrapper {
          order: 0;
          width: 30%;
        }
      }
    }
  }
`;

const SVGWrapper = styled.div`
  width: 80%;
  max-width: 500px;
  margin: 0 auto 25px;
`;

const CardsWrapper = styled.div`
  ${({ theme }) => theme.media.min.mobile`
    display: flex;
    margin-top: 50px;
  `}
`;

const titleStyles = css`
  font-family: 'Libre Baskerville', serif;
  color: white;
  z-index: 2;
`;

const Span = styled.span`
  color: white;
  font-family: 'Libre Baskerville', serif;
`;

const HeaderTitle = styled.h1`
  ${titleStyles}
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0 15%;
  text-align: center;
  ${({ theme }) => theme.media.min.mobile`
    font-size: 1.8rem;
    padding: 0;
  `}
`;

const Title = styled.h3`
  font-weight: lighter;
  font-family: 'Libre Baskerville', serif;
  margin-top: 20px;
  margin-bottom: 35px;
  padding-bottom: 10px;
  text-align: center;
  ${({ theme }) => theme.media.min.tablet`
    font-size: 2.5rem;
  `}
`;

const TextBlock = styled.p`
  padding: 5px;
  margin-bottom: 15px;
  font-size: 2em;
`;

const ExploreMoreBtn = styled.div`
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 37.5%;
  transform: translateX(-50%);
`;
