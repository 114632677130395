/**
 *
 * Memorial
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

export const Memorial = ({
  fill="#8dc63f",
  width = '100%',
  height = '100%',
  style = {},
}) => (
  <svg height={height} width={width} style={style} viewBox="0 0 100 100">
    <polygon
      fill={fill}
      points="58.2,15.5 50,6 41.8,15.5 39.6,72.6 50,72.6 60.4,72.6 "
    />
    <rect fill={fill} x="34.4" y="75.7" width="31.1" height="7.8" />
    <rect fill={fill} x="26.5" y="86.2" width="47" height="7.8" />
  </svg>
);

Memorial.propTypes = SVGPropTypes;

export default Memorial;
